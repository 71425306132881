import { isAfter } from 'date-fns';
import { REPORT_OPEN_DATE } from '../helpers';
import { useCyclesData } from './useCyclesData';

export function useCyclePeriods() {
  const { currentCycle } = useCyclesData();
  const now = useNow();

  const isReviewPeriodOpen = computed(() => {
    return (
      !!currentCycle.value &&
      now.value >= currentCycle.value.reviewStartDate &&
      now.value < currentCycle.value.endDate
    );
  });

  const isReportPeriodOpened = computed(() => {
    const date = new Date(now.value);
    const reportOpenDate = new Date(
      Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), REPORT_OPEN_DATE)
    );

    return isAfter(date, reportOpenDate);
  });

  const isPromotionPeriodActive = computed(() =>
    currentCycle.value
      ? now.value > currentCycle.value.orderPlacementDeadlineDate
      : false
  );

  return {
    isReviewPeriodOpen,
    isReportPeriodOpened,
    isPromotionPeriodActive,
  };
}
